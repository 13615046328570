import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_table = _resolveComponent("search-table")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_tabs, {
      style: {"background-color":"#fff"},
      tabBarStyle: { paddingLeft: '20px' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "每日业绩"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_search_table, { opts: _ctx.dailyOpts }, null, 8, ["opts"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "月度业绩"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_search_table, { opts: _ctx.monthOpts }, null, 8, ["opts"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "3",
          tab: "总业绩"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_search_table, { opts: _ctx.totalOpts }, null, 8, ["opts"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}