
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'PerfStat',
  components: { SearchTable },
  setup() {
    const { mapper, https } = useInject();
    const { userMeta, isAgent } = useGetters();
    const uidOpts = {
      field: 'uid',
      label: '代理',
      type: 'agent',
      required: true,
      visible: () => !isAgent,
      defVal: () => (isAgent ? userMeta.uid : '')
    };

    const cols = [
      {
        field: 'suit',
        label: '业绩类别',
        options: mapper?.stat.perfSuitType
      },
      {
        field: 'bno',
        label: '渠道',
        options: mapper?.device.backerNo
      },
      {
        field: 'cnt',
        label: '数量'
      }
    ];

    const handleData = (list: DataItem[]) => {
      list.forEach(x => {
        if (!x.cnt) {
          x.cnt = 0;
        }
      });
      return list;
    };

    const dailyOpts = {
      pageable: false,
      search: {
        criteria: {
          evaluate(data: DataItem) {
            return { uid: data.uid, date: data.date.substring(2, 4) + data.date.substring(5, 7) + data.date.substring(8, 10) };
          },
          cols: [
            uidOpts,
            {
              field: 'date',
              label: '日期',
              type: 'date',
              required: true
            }
          ]
        },
        remote: {
          action: https?.stat.infoPerfDaily,
          handle: handleData
        }
      },
      cols
    };

    const monthOpts = {
      pageable: false,
      search: {
        criteria: {
          evaluate(data: DataItem) {
            // 虽然是月份，但传参却是date...
            return { uid: data.uid, date: data.month.substring(2, 4) + data.month.substring(5, 7) };
          },
          cols: [
            uidOpts,
            {
              field: 'month',
              label: '月份',
              type: 'month',
              required: true
            }
          ]
        },
        remote: {
          action: https?.stat.infoPerfMonthly,
          handle: handleData
        }
      },
      cols
    };

    const totalOpts = {
      pageable: false,
      search: {
        criteria: {
          cols: [uidOpts]
        },
        remote: {
          action: https?.stat.infoPerfTotal,
          handle: handleData
        }
      },
      cols
    };

    return { dailyOpts, monthOpts, totalOpts };
  }
});
